@media (min-width: 0px) {
    .image {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .image {
        width: 75%;
    }
}

@media (min-width: 1200px) {
    .image {
        width: 50%;
    }
}

.css-1783ovr-MuiPaper-root-MuiCard-root{
    height:100%;
}