@import url('https://fonts.googleapis.com/css2?family=Alegreya:wght@800&family=Open+Sans&display=swap');


.navlink {
    font-family: 'Alegreya', serif;
    color: #585a63;
    font-size: 1.5em !important;
    text-align: bottom;
}

.navlink-icon {
    font-family: 'Alegreya', serif;
    color: #585a63;
    font-size: 1.2em !important;
    text-align: bottom;
}

.icon-online {
    position: relative;
    left: 25%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}


.active>li>a {

    position: relative;
}
.active>li>a::before {
    content: "";
    background: #5d6960;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1.5px;
    width: 125px;
}

.active>li>div {

    position: relative;
}

.active>li>div::before {
    content: "";
    background: white;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 125px;
}

